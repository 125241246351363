import React from "react";
import LMS from "../components/LMS";
import { Route, Switch, Redirect } from "react-router-dom";
import CertificateUserFormContainer from "../components/certificate_user/CertificateUserFormContainer";
import ContentCategoryContainer from "../components/contents/ContentCategoryContainer";
import ContentCommentContainer from "../components/contents/ContentCommentContainer";
import ContentFeedContainer from "../components/contents/ContentFeedContainer";
import ContentHighlightContainer from "../components/contents/ContentHighlightContainer";
import CertificatesContainer from "../components/certificates/CertificatesContainer";
import CourseCheckoutContainer from "../components/courseCheckout/CourseCheckoutContainer";
import CourseFilesPageContainer from "../components/courseFiles/CourseFilesPageContainer";
import CourseAnnotationsContainer from "../components/courses/CourseAnnotationsContainer";
import CourseContainer from "../components/courses/CourseContainer";
import CoursesContainer from "../components/courses/CoursesContainer";
import CoursesUserContainer from "../components/courses/CoursesUserContainer";
import LessonContainer from "../components/lessons/LessonContainer";
import CreateMockContainer from "../components/mocks/CreateMockContainer";
import MockContainer from "../components/mocks/MockContainer";
import MockModalContainer from "../components/mocks/MockModalContainer";
import MockRankingModalContainer from "../components/mocks/MockRankingModalContainer";
import MockResultsModalContainer from "../components/mocks/MockResultsModalContainer";
import MockModalExamFormContainer from "../components/mocks/MockModalExamFormContainer";
import MockModalFormContainer from "../components/mocks/MockModalFormContainer";
import MocksContainer from "../components/mocks/MocksContainer";
import MyPerformanceContainer from "../components/myPerformance/MyPerformanceContainer";
import NetworkingContainer from "../components/networking/NetworkingContainer";
import NetworkingProfileContainer from "../components/networking/NetworkingProfileContainer";
import ProfileEditAddressFormContainer from "../components/profileEdit/ProfileEditAddressFormContainer";
import ProfileEditChangePasswordFormContainer from "../components/profileEdit/ProfileEditChangePasswordFormContainer";
import ProfileEditPersonalFormContainer from "../components/profileEdit/ProfileEditPersonalFormContainer";
import PromotionsContainer from "../components/promotion/PromotionsContainer";
import QuestionSearchContainer from "../components/questions/QuestionSearchContainer";
import ImpersonateContainer from "../components/signIn/ImpersonateContainer";
import SupportFormContainer from "../components/support/SupportFormContainer";
import { canAccessContent } from "../selectors/contentSelectors";
import { canAccessNetworking } from "../selectors/networkingSelectors";
import { canAccessCompany } from "../selectors/companySelectors";
import {
  canAccessClassrooms,
  canAccessClassroom,
} from "../selectors/classroomSelectors.js";
import { restrictAccess } from "./helpers";
import NotFound from "../components/notFound/NotFound";
import { allowList } from "../components/lobby/RequireSignIn";
import ClassroomsContainer from "../components/classrooms/ClassroomsContainer";
import ClassroomContainer from "../components/classrooms/ClassroomContainer";
import ClassroomUserPerformanceContainer from "../components/classrooms/ClassroomUserPerformanceContainer";
import MyPerformanceMobileContainer from "../components/mobile/myPerformance/MyPerformanceContainer";
import MocksMobileContainer from "../components/mobile/mocks/MocksContainer";
import MockMobileContainer from "../components/mobile/mocks/MockContainer";
import MockCustomFormContainer from "../components/mobile/mocks/MockCustomFormContainer";
import MockExamFormContainer from "../components/mobile/mocks/MockExamFormContainer";
import MockRankingContainer from "../components/mobile/mocks/MockRankingContainer";
import MockResultsContainer from "../components/mobile/mocks/MockResultsContainer";
import QuestionSearchMobileContainer from "../components/mobile/questions/QuestionSearchContainer";
import MockListContainer from "../components/mobile/mocks/MockListContainer";
import MockRankingPageContainer from "../components/mocks/MockRankingPageContainer";
import InvoicesContainer from "../components/invoices/InvoicesContainer";
import CompanyContainer from "../components/companies/CompanyContainer";
import GreetingQuestions from "../components/greeting/GreetingQuestions";
import HomeContainer from "../components/home/HomeContainer.js";

const lobbyRoutesList = [...allowList, "/signin"];

export default function LmsRoutes(props) {
  return (
    <LMS {...props}>
      <Switch>
        <Route path="/" component={HomeContainer} exact />
        <Route path="/all-courses" component={CoursesContainer} exact />
        <Route path="/impersonate" component={ImpersonateContainer} exact />
        <Route path="/my-courses" component={CoursesUserContainer} exact />
        <Route path="/courses/:id/" component={CourseContainer} exact />
        <Route
          path="/classrooms"
          component={restrictAccess(ClassroomsContainer, canAccessClassrooms)}
          exact
        />
        <Route
          path="/classrooms/:classroomId"
          component={restrictAccess(ClassroomContainer, canAccessClassroom)}
          exact
        />
        <Route
          path="/classrooms/:classroomId/users/:userId/performance"
          component={restrictAccess(
            ClassroomUserPerformanceContainer,
            canAccessClassroom
          )}
          exact
        />
        <Route
          path="/companies/:id"
          component={restrictAccess(CompanyContainer, canAccessCompany)}
          exact
        />
        <Route path="/courses/:id/lessons/:id" component={LessonContainer} />
        <Route
          path="/courses/:courseId/checkout"
          component={CourseCheckoutContainer}
        />
        <Route
          path="/courseOffers/:courseOfferId"
          component={CourseCheckoutContainer}
        />
        <Route
          path="/profile/edit/personal"
          component={ProfileEditPersonalFormContainer}
          exact
        />
        <Route
          path="/profile/edit/address"
          component={ProfileEditAddressFormContainer}
          exact
        />
        <Route
          path="/profile/edit/change-password"
          component={ProfileEditChangePasswordFormContainer}
          exact
        />
        <Route
          path="/networking"
          component={restrictAccess(NetworkingContainer, canAccessNetworking)}
          exact
        />
        <Route
          path="/networking/:id"
          component={restrictAccess(
            NetworkingProfileContainer,
            canAccessNetworking
          )}
        />
        <Route path="/certificates" component={CertificatesContainer} />
        <Route path="/invoices" component={InvoicesContainer} />
        <Route path="/promotions" component={PromotionsContainer} />
        <Route path="/support" component={SupportFormContainer} />
        <Route path="/mocks" component={MocksContainer} exact />
        <Route path="/mocks/new" component={MocksContainer} exact />
        <Route path="/mocks/custom/new" component={MocksContainer} exact />
        <Route path="/mocks/exam/new" component={MocksContainer} exact />
        <Route path="/questions" component={MocksContainer} exact />
        <Route path="/questions/:questionId" component={MocksContainer} exact />
        <Route path="/mocks/:mockId/ranking" component={MocksContainer} exact />
        <Route path="/mocks/:mockId/results" component={MockContainer} exact />
        <Route path="/mocks/:mockId" component={MockContainer} exact />
        <Route path="/greetings" component={GreetingQuestions} exact />
        <Route
          path="/mockRanking/:mockId"
          component={MockRankingPageContainer}
          exact
        />
        <Route
          path="/mockTemplates/:mockTemplateId"
          component={CreateMockContainer}
          exact
        />
        <Route
          path="/publications/:commentId"
          component={restrictAccess(ContentCommentContainer, canAccessContent)}
        />
        <Route
          path="/contents/:contentId/highlights"
          component={restrictAccess(
            ContentHighlightContainer,
            canAccessContent
          )}
          exact
        />
        <Route
          path="/contents/:contentId/categories"
          component={restrictAccess(ContentCategoryContainer, canAccessContent)}
          exact
        />
        <Route
          path="/contents/:contentId/feed"
          component={restrictAccess(ContentFeedContainer, canAccessContent)}
          exact
        />
        <Route
          path="/my-performance"
          component={MyPerformanceContainer}
          exact
        />
        <Route
          path="/valide-seu-certificado"
          component={CertificateUserFormContainer}
          exact
        />
        <Route
          path="/courses/:courseId/annotations"
          component={CourseAnnotationsContainer}
          exact
        />
        <Route
          path="/courses/:courseId/downloads"
          component={CourseFilesPageContainer}
          exact
        />

        {/* MOBILE ROUTES */}

        <Route path="/mobile/mocks" component={MocksMobileContainer} exact />
        <Route path="/mobile/mocks/list" component={MockListContainer} exact />
        <Route
          path="/mobile/mocks/:mockId"
          component={MockMobileContainer}
          exact
        />
        <Route
          path="/mobile/mocks/custom/new"
          component={MockCustomFormContainer}
          exact
        />
        <Route
          path="/mobile/mocks/exam/new"
          component={MockExamFormContainer}
          exact
        />
        <Route
          path="/mobile/mocks/:mockId/ranking"
          component={MockRankingContainer}
          exact
        />
        <Route
          path="/mobile/mocks/:mockId/results"
          component={MockResultsContainer}
          exact
        />
        <Route
          path="/mobile/mockTemplates/:mockTemplateId"
          component={CreateMockContainer}
          exact
        />

        <Route
          path="/mobile/questions"
          component={QuestionSearchMobileContainer}
          exact
        />

        <Route
          path="/mobile/my-performance"
          component={MyPerformanceMobileContainer}
          exact
        />

        {/* DEFAULTS */}

        {lobbyRoutesList.map((lobbyRoute) => (
          <Redirect from={lobbyRoute} to="/" />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
      <Switch>
        <Route path="/mocks/new" component={MockModalContainer} exact />
        <Route
          path="/mocks/custom/new"
          component={MockModalFormContainer}
          exact
        />
        <Route
          path="/mocks/exam/new"
          component={MockModalExamFormContainer}
          exact
        />
        <Route path="/questions" component={QuestionSearchContainer} exact />
        <Route
          path="/questions/:questionId"
          component={QuestionSearchContainer}
          exact
        />
        <Route
          path="/mocks/:mockId/ranking"
          component={MockRankingModalContainer}
          exact
        />
        <Route
          path="/mocks/:mockId/results"
          component={MockResultsModalContainer}
          exact
        />
      </Switch>
    </LMS>
  );
}
