import React, { useState, useCallback } from "react";
import Button from "../library/Button";
import Label from "../library/forms/Label";
import Form from "../library/forms/Form";
import RadioField from "../library/forms/RadioField";
import TextField from "../library/forms/TextField";
import TextAreaField from "../library/forms/TextAreaField";
import { css } from "glamor";
import { spacing, colors } from "../library/theme";
import { companies, jobTitles } from "./GreetingFormContainer";

const paginationWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  marginTop: spacing.m,
});
const fieldsWrapper = css({
  "& input": {
    marginLeft: spacing.xs,
  },
  "& label::after": {
    content: " ",
  },
});
const labelStyle = {
  fontWeight: "bold",
  color: colors.greyShadow,
};

export default function GreetingForm(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <div className={fieldsWrapper}>
        <Label style={labelStyle}>
          Você trabalha no mercado financeiro atualmente?*
        </Label>
        <RadioField name="alreadyWorksInFinance" value="Sim" label="Sim" />
        <RadioField name="alreadyWorksInFinance" value="Não" label="Não" />

        <div
          className={css(
            props.alreadyWorksInFinance != "Sim" && { display: "none" }
          )}
        >
          <Label style={labelStyle}>Onde trabalha?*</Label>
          {companies.map((company) => (
            <RadioField
              name="employerCompany"
              key={company}
              value={company}
              label={company}
            />
          ))}

          <TextField
            name="otherEmployerCompany"
            label="Outro"
            labelLayout={labelStyle}
          />
        </div>

        <div
          className={css(
            { marginTop: spacing.m },
            props.alreadyWorksInFinance != "Não" && { display: "none" }
          )}
        >
          <Label style={labelStyle}>
            Porque você gostaria de fazer um curso da T2?
          </Label>
          <TextAreaField name="reasonToStudy" />
        </div>
      </div>

      <div className={css(fieldsWrapper, { margin: `${spacing.m} 0px` })}>
        <Label style={labelStyle}>Qual seu cargo?</Label>
        {jobTitles.map((jobTitle) => (
          <RadioField
            key={jobTitle}
            name="jobTitle"
            value={jobTitle}
            label={jobTitle}
          />
        ))}
        <TextField
          name="otherJobTitle"
          label="Outro"
          labelLayout={labelStyle}
        />
      </div>

      <Button
        type="submit"
        color="primary"
        onClick={props.handleSubmit}
        loading={props.submitting}
        disabled={
          props.pristine ||
          props.submitting ||
          props.invalid ||
          (props.alreadyWorksInFinance == "Sim" && !props.employerCompany)
        }
      >
        Enviar
      </Button>
    </Form>
  );
}
