import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Select } from "semantic-ui-react";
import { baseUrl } from "../../processes/helpers/api";
import CertificationExamModalContainer from "../certificationExamModal/CertificationExamModalContainer";
import { Page } from "../library";
import Button from "../library/Button";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import { colors, spacing } from "../library/theme";
import LessonActionsContainer from "./LessonActionsContainer";
import LessonQuestionContainer from "./LessonQuestionContainer";
import LessonRatingFormContainer from "./LessonRatingFormContainer";
import VideoContainer from "./VideoContainer";

const disabledClass = css({
  padding: spacing.xxl,
  textAlign: "center",
});
const disableLabel = css({
  marginBottom: spacing.m,
});
const headerClass = css({
  display: "flex",
  justifyContent: "right",
  gap: spacing.xs,
  ":not(:empty)": {
    paddingBottom: spacing.m,
  },
});
const videoFooterContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginTop: spacing.s,
});
const dropdownClass = css({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: `${spacing.s} !important`,
  width: "100% !important",
  ".ui.selection.dropdown": {
    minHeight: 30,
  },
  "> div.menu > div.item > span": {
    fontSize: 13,
  },
}).toString();

const playerOptions = [
  { key: 1, value: "vimeo", text: "Player 01" },
  { key: 2, value: "scaleup", text: "Player 02" },
];

export default function Lesson({
  lesson,
  watched,
  currentUser,
  canAccessLesson,
  canAccessCourse,
  course,
  loading,
  handlePlayerOption,
  playerOption,
  ratingQuestion,
}) {
  const [openRating, setOpenRating] = useState(true);
  const handleOpenRating = useCallback(() => setOpenRating(true), []);
  const handleCloseRating = useCallback(() => setOpenRating(false), []);
  const showPlayerOption = canAccessCourse && !!lesson?.scaleupUrl;

  return loading || !course || !lesson ? (
    <Page>
      <Spinner wrapped text="Carregando informações da aula..." />
    </Page>
  ) : (
    <Page title={!!lesson ? lesson.name : null}>
      {!canAccessLesson ? (
        <div className={disabledClass}>
          <h4 className={disableLabel}>
            Compre este curso para ter acesso ao conteúdo completo!
          </h4>
          <Link
            component={Button}
            secondary
            to={
              course?.salesChannel == "hotmart"
                ? undefined
                : `/courses/${course.id}/checkout`
            }
            href={
              course?.salesChannel == "hotmart" ? course.paymentUrl : undefined
            }
            target={course?.salesChannel == "hotmart" && "_blank"}
          >
            COMPRAR
          </Link>
        </div>
      ) : (
        <>
          <CertificationExamModalContainer />
          <div className={headerClass}>
            {currentUser?.meta?.features?.canManageCourses && (
              <Link
                component={Button}
                href={`${baseUrl}/manager/lessons/${lesson.id}/edit`}
                target="_blank"
              >
                Editar aula
              </Link>
            )}
            {lesson?.certificateEnabled && watched && (
              <Button
                href={lesson?.certificateUrl}
                target="_blank"
                circular
                secondary
              >
                EMITIR CERTIFICADO
              </Button>
            )}
          </div>
          <VideoContainer
            lesson={lesson}
            openRating={openRating}
            handleOpenRating={handleOpenRating}
          />
          {(showPlayerOption || ratingQuestion) && (
            <div className={videoFooterContainer}>
              <div>
                {showPlayerOption && (
                  <>
                    <label>Escolha seu player de vídeo</label>
                    <Select
                      className={dropdownClass}
                      options={playerOptions}
                      onChange={handlePlayerOption}
                      defaultValue={playerOption}
                      fluid
                      icon="chevron down"
                    />
                  </>
                )}
              </div>
              {ratingQuestion && watched && (
                <Button onClick={handleOpenRating} primary>
                  AVALIAR
                </Button>
              )}
            </div>
          )}
          <LessonActionsContainer />
          {watched && (
            <>
              {ratingQuestion && (
                <LessonRatingFormContainer
                  key={`lesson${lesson.id}RatingQuestion${ratingQuestion.id}Form`}
                  form={`lesson${lesson.id}RatingQuestion${ratingQuestion.id}Form`}
                  ratingQuestionTitle={ratingQuestion.title}
                  ratingQuestionId={ratingQuestion.id}
                  open={openRating}
                  onClose={handleCloseRating}
                />
              )}
              <LessonQuestionContainer />
            </>
          )}
        </>
      )}
    </Page>
  );
}
