import React from "react";
import { Page, Spinner } from "../library";
import CourseList from "./CourseList";

export function CoursesData(props) {
  const { loading, courseCategories, justOwned } = props;

  return loading ? (
    <Spinner wrapped />
  ) : (
    <>
      {courseCategories && (
        <CourseList courseCategories={courseCategories} justOwned={justOwned} />
      )}
    </>
  );
}

export default function Courses(props) {
  return (
    <Page title="Todos os Cursos">
      <CoursesData {...props} />
    </Page>
  );
}
